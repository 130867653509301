"use client";

// Libraries
import Link from "next/link";
import Head from "next/head";
import Lottie from "lottie-react";
import errorBot from "../public/static/animations/error-bot.json";

// Components
import Button from "../components/ui/Button/Button";
import Logo from "../components/ui/Logo/Logo";

export default function NotFound() {
    return (
        <>
            <Head>
                <title>Cette page n'existe pas | Believemy</title>
            </Head>
            <main className="flex md:flex-row flex-col items-center md:justify-between justify-center lg:px-24 px-5 container mx-auto h-screen">
                <div className="md:order-1 order-2 md:w-1/2">
                    <div className="md:mt-0 mt-5 flex flex-col justify-center items-center">
                        <div className="hidden dark:block">
                            <Logo ratio={1.5} white icon />
                        </div>
                        <div className="block dark:hidden">
                            <Logo ratio={1.5} icon />
                        </div>
                        <p className="mt-5 text-lg dark:text-white">
                            Cette page n'existe pas ou a été supprimée.
                        </p>
                        <div className="flex justify-center mt-3">
                            <Link href="/">
                                <Button type="pop">
                                    Retourner à l'accueil
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="md:order-2 order-1 md:w-1/2 w-48">
                    <Lottie
                        animationData={errorBot}
                        autoplay={true}
                        loop={true}
                        style={{ width: "100%" }}
                    />
                </div>
            </main>
        </>
    );
}
